import { mdiHomeVariantOutline } from "@mdi/js";
import Icon from "@mdi/react";
import { Col, Form, Row } from "react-bootstrap";
import { AppIcon, FontSize, Heading, useEloise } from "typed-component-gallery";
import { camelCaseToNormalCase } from "../../functions/helpers";

export const shadowStyle = {
  border: "1px solid #dedede",
  borderRadius: "10px",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
};

export const SingleImage: React.FC<{ photo: string }> = ({ photo }) => {
  return (
    <img
      src={photo}
      className="my-3"
      style={{
        ...shadowStyle,
        maxWidth: "100%",
        borderRadius: "5px",
      }}></img>
  );
};

export const Tile: React.FC<{
  title?: string;
  icon?: string;
  children: any;
  iconFunction?: Function;
  iconTitle?: string;
  style?: any;
  lg?: number;
  headingSize?: 6 | 1 | 2 | 3 | 4 | 5 | FontSize | undefined;
}> = ({
  title,
  children,
  iconFunction,
  icon,
  iconTitle,
  headingSize,
  style,
  lg,
}) => {
  const { theme } = useEloise();
  return (
    <Col
      lg={lg ?? undefined}
      style={{
        ...style,
        border: "1px solid #dedede",
        borderRadius: "10px",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
      }}
      className="p-3 my-3  mx-auto ">
      <Row>
        <Col lg={6}>
          {title && (
            <Heading
              size={headingSize ?? 3}
              align="left">
              {title}
            </Heading>
          )}
        </Col>
        <Col
          lg={6}
          className="text-end">
          {icon &&
            (iconFunction ? (
              <AppIcon
                icon={icon ?? mdiHomeVariantOutline}
                size={2}
                color={theme.colors[1]}
                onClick={iconFunction}
                title={iconTitle ?? "icon"}
              />
            ) : (
              <Icon
                path={icon ?? mdiHomeVariantOutline}
                size={2}
                color={theme.colors[1]}
              />
            ))}
        </Col>
      </Row>

      {children}
    </Col>
  );
};

export const DoubleImage: React.FC<{ photo1: string; photo2: string }> = ({
  photo1,
  photo2,
}) => {
  return (
    <div className="my-3">
      <img
        src={photo1}
        style={{
          ...shadowStyle,
          maxWidth: "50%",
          borderRadius: "5px 0 0 5px",
        }}></img>
      <img
        src={photo2}
        style={{
          ...shadowStyle,
          maxWidth: "50%",
          borderRadius: "0 5px 5px 0    ",
        }}></img>
    </div>
  );
};

export const Total: React.FC<{ value: number; label?: string }> = ({
  value,
  label,
}) => {
  return (
    <div>
      <Row>
        <Col
          lg={11}
          className="mx-auto"
          style={{ borderBottom: "double 3px black", height: "10px" }}></Col>
      </Row>
      <Form.Group
        as={Row}
        className="my-3 p-2">
        <Form.Label
          column
          sm="4">
          {label ?? "Total"}
        </Form.Label>
        <Col sm="8">
          <Form.Control
            type="text"
            readOnly
            value={value.toFixed(2)}
          />
        </Col>
      </Form.Group>
    </div>
  );
};

export const Options: React.FC<{
  options: any;
  updateOption: Function;
  view: string;
  setViewFunction: Function;
  headingSize?: 6 | 1 | 2 | 3 | 4 | 5 | FontSize | undefined;
}> = ({ options, updateOption, view, setViewFunction, headingSize }) => {
  const { theme } = useEloise();
  return (
    <div
      className="p-3"
      style={{ ...shadowStyle, backgroundColor: theme.colors[1] }}>
      <Heading
        align="left"
        color="white"
        size={headingSize ?? 3}>
        Options
      </Heading>
      <Form>
        <Form.Group>
          {Object.entries(options).map((option: any, i: number) => {
            console.log(option);
            return (
              <Form.Check
                style={{ color: "white" }}
                type="switch"
                id="eating-out-switch"
                label={camelCaseToNormalCase(option[0])}
                checked={option[1]}
                onChange={() => updateOption(option[0], !option[1])}
              />
            );
          })}
        </Form.Group>
        <Form.Group>
          <Form.Label style={{ color: "white" }}>View</Form.Label>
          <Form.Control
            as="select"
            value={view}
            onChange={(e) => setViewFunction(e.target.value)}>
            <option value="month">Month</option>
            <option value="day">Day</option>
            <option value="week">Week</option>
            <option value="trip">Trip</option>
          </Form.Control>
        </Form.Group>
      </Form>
    </div>
  );
};
