import { Theme } from "typed-component-gallery";

export const themeConfig: Theme = {
  colors: [
    "hsla(216, 96%, 10%,1)",
    "hsla(20, 100%, 77%, 1)",
    "hsla(5, 88%, 57%, 1)",
  ],

  white: "hsla(90, 60%, 96%, 1)",
  font: "roboto",

  fontSize: "1.25px",
  borderRadius: "5px",
  border: "1px solid black",
  mode: "light",
  heading: {
    color: "hsla(216, 96%, 10%,1)",
  },

  input: {
    border: true,
    rounded: true,
    extLabel: true,
  },
};
