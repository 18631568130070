import { mdiHomePlus } from "@mdi/js";
import React, { useEffect, useState } from "react";
import { Carousel, Col, Container, ListGroup, Row } from "react-bootstrap";
import { useParams } from "react-router";
import {
  Button,
  ChildrenModal,
  Heading,
  Input,
  LargeTextInput,
  Loading,
  View,
  useEloise,
} from "typed-component-gallery";
import { v4 as uuidv4 } from "uuid";
import waveBreak from "../../assets/waveBreak.png";
import { parseAirbnbHTML } from "../../utils/parseAirbnb";
import { AirbnbSelector } from "../widgets/airbnb";
import { Tile } from "../widgets/placeWidgets";
import { ProfileExpenses } from "../widgets/profileExpenses";
import WeatherDisplay from "../widgets/weather";
import { defaultFont } from "./dash";
const WaveBreak = () => {
  return (
    <Row className="my-3 text-center">
      <img
        src={waveBreak}
        height={20}
      />
    </Row>
  );
};
export const Place: React.FC = () => {
  const { id } = useParams();
  const { logic } = useEloise();

  const [newAir, setNewAir] = useState(false);
  const [newAirHtml, setNewAirHtml] = useState<string>("");
  const [newAirPrice, setNewAirPrice] = useState("");

  const [place, setPlace] = useState<any>();
  useEffect(() => {
    const fetchData = async () => {
      let data = await logic.fb.docs.getBaseDoc("places/" + id);
      if (data) {
        console.log(data);
        setPlace(data);
        setNotes(data.data.notes);
      }
    };
    fetchData();
  }, []);

  const [notes, setNotes] = useState<string>(" ");

  useEffect(() => {
    const updateNotes = async () => {
      let data = await logic.fb.docs.setBaseDoc("places/" + id, { notes });
    };
    if (notes !== "" && place && notes !== place.data.notes) {
      updateNotes();
    }
  }, [notes]);

  const handleNewAir = async () => {
    let { link, text } = parseAirbnbHTML(newAirHtml);
    let ots = { link, text, price: parseInt(newAirPrice), id: uuidv4() };
    let test = await logic.fb.docs.setBaseDoc("places/" + id, {
      airbnbs: [...place.data.airbnbs, ots],
    });
    setNewAir(false);
    setNewAirHtml("");
    setNewAirPrice("");
  };

  if (place) {
    return (
      <Container fluid>
        <Row className="mb-4">
          <Col lg={3}></Col>
          <Col
            lg={3}
            className="mx-auto">
            <View>
              <Heading size={5}>
                {place.data.city + " , " + place.data.country}
              </Heading>
            </View>
          </Col>{" "}
          <Col
            lg={3}
            className="mx-auto text-center">
            <img
              height={200}
              src={place.data.shape}></img>
          </Col>
          <Col lg={3}></Col>
        </Row>
        <WaveBreak />
        <Row className="my-4">
          <ProfileExpenses
            lg={9}
            id={place.id}
            type="expense"
          />
        </Row>
        <WaveBreak />

        <Row className="my-4">
          <ChildrenModal
            open={newAir}
            onClose={() => setNewAir(false)}>
            <Container style={{ minWidth: "400px" }}>
              <Heading size={3}>New Airbnb</Heading>
              <Row>
                <Col
                  lg={9}
                  className="mx-auto">
                  <LargeTextInput
                    state={newAirHtml}
                    setState={setNewAirHtml}
                    title="HTML"></LargeTextInput>
                </Col>
              </Row>
              <Row>
                <Heading style={{ fontFamily: defaultFont }}>
                  Paste Embed Code Above
                </Heading>
              </Row>

              <Row>
                <Col
                  lg={9}
                  className="mx-auto">
                  <Input
                    extLabel
                    label="Price"
                    state={newAirPrice}
                    setState={setNewAirPrice}></Input>
                </Col>
              </Row>
              <Row>
                <Col
                  lg={5}
                  className="mx-auto">
                  {newAirHtml && newAirPrice && (
                    <Button onClick={handleNewAir}>Save</Button>
                  )}
                </Col>
              </Row>
            </Container>
          </ChildrenModal>

          <Tile
            lg={9}
            title="Accomodation"
            iconFunction={() => setNewAir(true)}
            icon={mdiHomePlus}>
            <Row>
              <AirbnbSelector
                ops={place.data.airbnbs}
                id={place.id}
              />
            </Row>
          </Tile>
        </Row>
        <WaveBreak />

        <Row className="my-4">
          <Tile
            title="Activities"
            lg={9}>
            {place.data.activities && place.data.activities.length > 0 && (
              <Carousel
                interval={3000}
                pause={false}>
                {place.data.activities.map((activity: any, index: number) => (
                  <Carousel.Item
                    key={index}
                    style={{ height: "60vh" }}>
                    <Row>
                      <Col
                        lg={9}
                        className="mx-auto">
                        {activity.link ? (
                          <a
                            href={activity.link}
                            target="_blank"
                            rel="noopener noreferrer">
                            <img
                              style={{ width: "auto" }}
                              className="d-block "
                              src={activity.img}
                              alt={activity.text}
                            />
                            <Carousel.Caption>
                              <h3>{activity.text}</h3>
                            </Carousel.Caption>
                          </a>
                        ) : (
                          <>
                            {" "}
                            <img
                              className="d-block w-100"
                              src={activity.img}
                              alt={activity.text}
                            />
                            <Carousel.Caption>
                              <h3>{activity.text}</h3>
                            </Carousel.Caption>
                          </>
                        )}
                      </Col>
                    </Row>
                  </Carousel.Item>
                ))}
              </Carousel>
            )}{" "}
          </Tile>
        </Row>
        <WaveBreak />

        <Row className="my-5">
          <Col
            lg={9}
            className="mx-auto">
            <Row>
              <Tile
                title="Info"
                lg={5}>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    Currency: {place.data.info.currency}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Language: {place.data.info.language}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    English Spoken: {place.data.info.english ? "Yes" : "No"}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Visa Requirements: {place.data.info.visa}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    {" "}
                    Lat: {`${place.data.lat}°${place.data.ns.toUpperCase()}`}
                  </ListGroup.Item>

                  <ListGroup.Item>Airport Lounge: True</ListGroup.Item>
                  {place.data.icloud && (
                    <ListGroup.Item>
                      <p>
                        {" "}
                        Photos:{" "}
                        <a
                          target="_blank"
                          href={place.data.icloud}>
                          View
                        </a>
                      </p>
                    </ListGroup.Item>
                  )}
                </ListGroup>
              </Tile>
              <Col lg={1}></Col>
              <Tile
                title="Notes"
                lg={6}>
                <Row>
                  <LargeTextInput
                    style={{ border: "none" }}
                    state={notes}
                    setState={setNotes}
                    placeholder=" "
                  />
                </Row>
              </Tile>
              {place.data.day ? (
                <Tile
                  lg={4}
                  title="Average Day">
                  <>
                    {place.data.day.map((ev: any) => (
                      <Row className="mx-3">
                        <Heading
                          size={2}
                          align="left">{`${ev.time} - ${ev.title}`}</Heading>
                        <Heading
                          align="left"
                          size={1}>
                          {ev.desc}
                        </Heading>
                      </Row>
                    ))}
                  </>
                </Tile>
              ) : (
                <Col
                  lg={5}
                  className="mx-auto"></Col>
              )}
              <Col lg={1}></Col>

              {place.data.areas ? (
                <Tile
                  lg={7}
                  title="Neighborhoods">
                  <>
                    {place.data.areas
                      .sort((a: any, b: any) => a.costIndex - b.costIndex)
                      .map((ev: any) => (
                        <>
                          <Row className="mx-3">
                            <Col lg={6}>
                              <Heading
                                size={2}
                                align="left">{`${ev.name}`}</Heading>
                              <Heading
                                size={2}
                                align="left">{`${ev.type}`}</Heading>
                            </Col>
                            <Col lg={6}>
                              <img
                                style={{ maxHeight: "200px" }}
                                src={ev.image}></img>
                            </Col>
                          </Row>
                          <hr />
                        </>
                      ))}
                  </>
                </Tile>
              ) : (
                <Col
                  lg={5}
                  className="mx-auto"></Col>
              )}
            </Row>
          </Col>
        </Row>
        <WaveBreak />
        <Row>
          {place.data.weather && place.data.weather.length > 0 && (
            <WeatherDisplay
              lg={9}
              weatherData={place.data.weather}
            />
          )}
        </Row>
      </Container>
    );
  } else {
    return <Loading />;
  }
};
