import { useEffect, useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { useEloise } from "typed-component-gallery";
import { PlanWidgetLarge } from "../widgets/plan";
import { TimeFrameOption } from "../widgets/timeframeSelector";
const rootStyle = { display: "flex", justifyContent: "center" };
const rowStyle = {
  margin: "200px 0",
  display: "flex",
  justifyContent: "space-between",
};
const boxStyle = { padding: "10px", border: "1px solid black" };
export const Plans: React.FC = () => {
  const [timeFrame, setTimeFrame] = useState<TimeFrameOption>({
    label: "1 year",
    value: "1y",
  });

  const [timeFrames, setTimeFrames] = useState<TimeFrameOption[]>([
    { label: "1 year", value: "1y" },
    { label: "5 years", value: "5y" },
    { label: "10 years", value: "10y" },
  ]);

  const { logic } = useEloise();
  const {
    docs: plans,
    loading,
    error,
  } = logic.fb.hooks.useCollection("plans", true);

  const [places, setPlaces] = useState<any>();

  const [finances, setFinances] = useState<any>();

  useEffect(() => {
    const fetchPlanData = async (x: any) => {
      let placeData = await Promise.all(
        x.data.places.map(async (planPlace: any) => {
          return await logic.fb.docs.getBaseDoc("places/" + planPlace.place);
        })
      );
      if (placeData) {
        return placeData;
      }
    };
    const totalExpenses = (places: any) => {
      return places.reduce((acc: number, item: any) => {
        let expenseSum = item.data.expenses.reduce(
          (iAcc: number, item2: any) => {
            iAcc += item2.value;
            return iAcc;
          },
          0
        );
        acc += expenseSum;
        return acc;
      }, 0);
    };
    const totalAirfare = (plan: any) => {
      return (
        plan.data.places.reduce((acc: number, item: any) => {
          acc += item.flight;
          return acc;
        }, 0) + plan.data.returnFlight
      );
    };

    const calculateExpenes = async () => {
      let tempFinance = await Promise.all(
        plans.map(async (plan: any) => {
          let planData = await fetchPlanData(plan);
          let airfare = totalAirfare(plan) * 2;
          let monthly = totalExpenses(planData);
          let total = airfare + monthly;
          let split = total / 2;
          let perDiem = split / 84;
          let ots = {
            title: plan.data.title,
            airfare,
            monthly,
            total,
            split,
            perDiem,
          };
          return ots;
        })
      );
      setFinances(tempFinance.sort((a: any, b: any) => a.total - b.total));
    };
    if (plans) {
      calculateExpenes();
    }
  }, [plans]);

  useEffect(() => {
    console.log(finances);
  }, [finances]);

  return (
    <Container fluid>
      {/* <TimeFrameSelector
        title={"Plans"}
        selectedModalOptions={timeFrames}
        setSelectedModalOptions={setTimeFrames}
        selectedTimeFrame={timeFrame}
        setSelectedTimeFrame={setTimeFrame}
      /> */}

      {plans &&
        plans.length > 0 &&
        plans

          .sort((a, b) => {
            const indexA = finances.findIndex(
              (finance: any) => finance.title === a.data.title
            );
            const indexB = finances.findIndex(
              (finance: any) => finance.title === b.data.title
            );
            return indexA - indexB;
          })
          .map((plan) => {
            // Step 2: Sort plans based on the order defined in finances
            return (
              <PlanWidgetLarge
                apple={true}
                key={plan.id}
                plan={plan}
              />
            );
          })}
      <Row className="my-4">
        <Col
          lg={9}
          className="mx-auto">
          <Table
            striped
            bordered
            hover>
            <thead>
              <tr>
                <th>Name</th>
                <th>Airfare</th>
                <th>Monthly</th>
                <th>Total</th>
                <th>Split</th>
                <th>Per Diem</th>
                <th>Delta</th>
                <th>Delta(cooking)</th>
              </tr>
            </thead>
            <tbody>
              {finances &&
                finances.length > 0 &&
                finances
                  .sort((a: any, b: any) => a.total - b.total)
                  .map((item: any, index: number) => (
                    <tr key={index}>
                      <td>{item.title}</td>
                      <td>{item.airfare}</td>
                      <td>{item.monthly}</td>
                      <td>{item.total}</td>
                      <td>{item.split}</td>
                      <td>{item.perDiem.toFixed(2)}</td>
                      <td>
                        {(
                          ((item.perDiem.toFixed(2) - 105) / 105) *
                          100
                        ).toFixed(2) + "%"}
                      </td>
                      <td>
                        {(((item.perDiem.toFixed(2) - 65) / 65) * 100).toFixed(
                          2
                        ) + "%"}
                      </td>
                    </tr>
                  ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};
