import {
  mdiCalculator,
  mdiCartPlus,
  mdiCounter,
  mdiLock,
  mdiPlus,
  mdiTuneVariant,
} from "@mdi/js";
import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import {
  AppIcon,
  Button,
  ChildrenModal,
  Heading,
  Input,
  Loading,
  useEloise,
} from "typed-component-gallery";
import { v4 as uuidv4 } from "uuid";
import {
  CalculatedExpense,
  CalculatedExpenseVar,
  Expense,
  FlatExpense,
  splitExpenses,
} from "../../types/Month";
import IconList from "./iconList";
import { Tile, Total } from "./placeWidgets";

export const ProfileExpenses: React.FC<{
  id: string;
  type: string;
  lg?: number;
}> = ({ id, type, lg }) => {
  const { logic, theme } = useEloise();

  const [newExpenseBool, setNewExpenseBool] = useState(false);
  const [newExpenseVar, setNewExpenseVar] = useState(false);

  const [newExpenseType, setNewExpenseType] = useState<string>();
  const [newVarType, setNewVarType] = useState<string>();
  const [newVarMin, setNewVarMin] = useState(0);
  const [newVarMax, setNewVarMax] = useState(1);

  const [newExpenseName, setNewExpenseName] = useState<string>();

  const [edit, setEdit] = useState(false);

  const [total, setTotal] = useState<number>(0);

  const [place, setField] = logic.fb.hooks.useDoc(`places/${id}`, 350);

  const createNewExpense = () => {
    if (newExpenseName && newExpenseType) {
      setField("Expenses", [
        ...place.expenses,
        { type: newExpenseType, title: newExpenseName, id: uuidv4() },
      ]);
    }
  };

  const createNewVar = (expense: CalculatedExpense) => {};

  const updateExpense = (
    e: { target: { value: any } },
    expense: Expense,
    key?: "title" | "vars",
    varId?: string
  ) => {
    const [calc, flat] = splitExpenses(place.expenses);
    if (expense.type === "calculated") {
      if (key === "vars" && varId) {
        calc.map((x: CalculatedExpense) => {
          if (x.id === expense.id) {
            x.vars.map((y: CalculatedExpenseVar) => {
              if (y.id == varId) {
                y.value = parseInt(e.target.value);
              }
              return y;
            });
          }
          return x;
        });
      }
    } else {
      flat.map((x: FlatExpense) => {
        if (x.id === expense.id) {
          if (key === "title") {
            x.title = e.target.value;
          } else {
            x.value = parseInt(e.target.value);
          }
        }
        return x;
      });
    }
    setField("expenses", [...flat, ...calc]);
  };

  const deleteExpense = (expense: Expense) => {
    setField(
      "expenses",
      place.expenses.filter((x: Expense) => x.title !== expense.title)
    );
  };

  const totalCalc = (): number => {
    if (!place.expenses || place.expenses.length == 0) return 0;

    const [calc, flat] = splitExpenses(place.expenses);

    let tot: number = flat.reduce(
      (acc: number, item: FlatExpense) => (acc += item.value),
      0
    );
    return tot;
  };

  useEffect(() => {
    if (place.expenses && place.expenses.length > 0) {
      setTotal(totalCalc());
    }
  }, [place.expenses]);

  if (!place.expenses || place.expenses.length < 1) {
    return <Loading />;
  } else {
    return (
      <Tile
        lg={lg ?? undefined}
        title="Expenses"
        icon={mdiCartPlus}
        iconFunction={() => setNewExpenseBool(true)}>
        <ChildrenModal
          open={newExpenseBool}
          onClose={() => {
            setNewExpenseBool(false);
          }}>
          <Container className="py-4 px-2">
            <Row className="my-3">
              <Col
                lg={11}
                className="mx-auto"
                style={{ borderBottom: "black 2px solid" }}>
                <Heading size={4}>New Expense</Heading>
              </Col>
            </Row>
            <Row className="my-4 mt-5">
              <IconList
                items={[
                  { text: "Fixed Expense", icon: mdiLock, value: "fixed" },
                  {
                    text: "Calculated Expense",
                    icon: mdiCalculator,
                    value: "calculated",
                  },
                ]}
                selected={newExpenseType}
                setSelected={setNewExpenseType}
              />
            </Row>
            <Row className="my-4">
              <Input
                state={newExpenseName}
                setState={setNewExpenseName}
                label="Name"
              />
            </Row>
            <Row className="my-4">
              <Button onClick={createNewExpense}>Add</Button>
            </Row>
          </Container>
        </ChildrenModal>
        <ChildrenModal
          style={{ minWidth: "30vw" }}
          open={newExpenseVar}
          onClose={() => {
            setNewExpenseVar(false);
          }}>
          <Container
            className="py-4 px-2"
            style={{ minWidth: "33vw" }}>
            <Row className="my-3">
              <Col
                lg={11}
                className="mx-auto"
                style={{ borderBottom: "black 2px solid" }}>
                <Heading size={4}>New Variable</Heading>
              </Col>
            </Row>
            <Row className="my-4 mt-5">
              <IconList
                items={[
                  { text: "Counter", icon: mdiCounter, value: "counter" },
                  {
                    text: "Slider",
                    icon: mdiTuneVariant,
                    value: "slider",
                  },
                ]}
                selected={newVarType}
                setSelected={setNewVarType}
              />
            </Row>
            <Row className="my-4">
              <Col
                lg={8}
                className="mx-auto">
                <Input
                  state={newExpenseName}
                  setState={setNewExpenseName}
                  label="Name"
                />
              </Col>
            </Row>
            {newVarType === "slider" && (
              <Row>
                <Col
                  lg={5}
                  className="mx-auto">
                  <Input
                    state={newVarMin}
                    setState={setNewVarMin}
                    label="Min"
                  />
                </Col>
                <Col
                  lg={5}
                  className="mx-auto">
                  <Input
                    state={newVarMax}
                    setState={setNewVarMax}
                    label="Max"
                  />
                </Col>
              </Row>
            )}

            <Row className="my-4">
              <Button onClick={createNewExpense}>Add</Button>
            </Row>
          </Container>
        </ChildrenModal>
        <Row>
          {place.expenses &&
            place.expenses.length > 0 &&
            place.expenses
              .filter((expense: any) => expense.type === "fixed")
              .map((expense: FlatExpense) => (
                <Form.Group
                  as={Row}
                  key={expense.id}
                  className="my-3">
                  {!edit ? (
                    <Col
                      lg={4}
                      onClick={() => {
                        setEdit(true);
                      }}>
                      <Form.Label>
                        <Form.Label>{expense.title}</Form.Label>
                      </Form.Label>
                    </Col>
                  ) : (
                    <Col lg={4}>
                      <Form.Control
                        value={expense.title ?? "Name"}
                        onChange={(e: any) =>
                          updateExpense(e, expense, "title")
                        }
                      />
                    </Col>
                  )}
                  <Col sm="8">
                    <Form.Control
                      type="number"
                      value={expense.value ?? 0}
                      onChange={(e: any) => updateExpense(e, expense)}
                    />
                  </Col>
                </Form.Group>
              ))}
        </Row>
        <Row>
          {place.expenses
            .filter((expense: any) => expense.type === "calculated")
            .map((expense: FlatExpense) => (
              <Row key={expense.id}>
                <div
                  style={{ border: "1px solid #D3D5D7", borderRadius: "5px" }}
                  className="py-2">
                  <Row>
                    <Col lg={11}>
                      <Heading
                        size={2}
                        align="left">
                        {expense.title}
                      </Heading>{" "}
                    </Col>
                    <Col lg={1}>
                      <AppIcon
                        color={theme.colors[0]}
                        icon={mdiPlus}
                        onClick={() => {
                          setNewExpenseVar(true);
                        }}
                        title="New Variable"
                      />
                    </Col>
                  </Row>

                  <Form.Group
                    key={expense.title}
                    as={Row}
                    className="my-3 ">
                    <Form.Label
                      column
                      sm="4">
                      Formula
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        type="string"
                        value={" "}
                        onChange={() => {}}
                      />
                    </Col>
                  </Form.Group>
                  <Total value={0} />
                </div>
              </Row>
            ))}
        </Row>
        <Row>
          <Total value={total} />
        </Row>
      </Tile>
    );
  }
};
