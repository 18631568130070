import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Heading, useEloise } from "typed-component-gallery";
import whaleSeason2 from "../../assets/whaleSeasonFullWidth.png";
import whaleSeason from "../../assets/whaleSeasonLogo.png";
import { PlanWidget, spotifyFont } from "../widgets/plan";

export const DEFAULT_ABSENT_RANK = 101;
export const defaultFont = `-apple-system, "system-ui", "Apple Color Emoji", "SF Pro", "SF Pro Icons", "Helvetica Neue", Helvetica, Arial, sans-serif`;
const Dash: React.FC<{}> = () => {
  const { theme, logic } = useEloise();

  const {
    docs: plans,
    loading,
    error,
  } = logic.fb.hooks.useCollection("plans", true);

  const [apple, setApple] = useState(true);

  return (
    <>
      <Container
        fluid
        style={{ width: "100%" }}
        className="mx-0 p-0">
        <Row style={{ background: "linear-gradient(to top, #fad3a9, white)" }}>
          <Col
            lg={5}
            className="mx-auto">
            <img
              src={whaleSeason}
              width={"100%"}
            />
          </Col>
        </Row>

        <Row className="g-0 mx-0 ">
          <Col
            lg={12}
            style={{
              backgroundImage: `url(${whaleSeason2})`,
              height: "100vh",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}>
            <Row
              className="mb-5"
              onClick={() => setApple(!apple)}>
              <Heading
                size={6}
                color={theme.colors[0]}
                style={{
                  fontStyle: "italic",
                  fontWeight: "bold",
                  fontFamily: "Pacifico",
                }}>
                Whale Season
              </Heading>
            </Row>
            <Row style={{ marginTop: "15vh" }}>
              <Col
                lg={4}
                className="mx-auto">
                {apple ? (
                  <iframe
                    allow="autoplay *; encrypted-media *; fullscreen *; clipboard-write"
                    height="450"
                    style={{
                      width: "100%",
                      maxWidth: "660px",
                      overflow: "hidden",
                      borderRadius: "10px",
                    }}
                    sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
                    src="https://embed.music.apple.com/us/playlist/whale-season/pl.u-NpXm9xpCmpY23MV"></iframe>
                ) : (
                  <iframe
                    style={{ borderRadius: "12px" }}
                    src="https://open.spotify.com/embed/playlist/4dMLemVKktKFI1751U7INQ?utm_source=generator"
                    width="100%"
                    height="352"
                    allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                    loading="lazy"></iframe>
                )}
              </Col>
              <Col
                lg={4}
                className="mx-auto no-mobile"
                style={{
                  overflowY: "scroll",
                  width: "100%",
                  maxWidth: "660px",
                  backgroundColor: apple ? "#f8f8fa" : "rgb(14, 132, 132)",
                  borderRadius: "10px",
                  height: apple ? "450px" : "352px",
                }}>
                <Heading
                  className="mt-2"
                  style={{
                    fontWeight: apple ? "normal" : "bold",
                    color: apple ? "rgb(60 60 67 / 60%)" : "white",
                    fontFamily: apple ? defaultFont : spotifyFont,
                  }}
                  size={4}>
                  Plans
                </Heading>
                {plans &&
                  plans.length > 0 &&
                  plans.map((plan) => (
                    <PlanWidget
                      apple={apple}
                      key={plan.id}
                      plan={plan}
                    />
                  ))}
              </Col>
            </Row>

            {/* <img

              src={whaleSeason2}
              width={"100%"}
              height={"auto"}
            /> */}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Dash;
