import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Heading, useEloise } from "typed-component-gallery";
import { Tile } from "../widgets/placeWidgets";

export const Item: React.FC<{ item: any }> = ({ item }) => {
  console.log(item);
  return (
    <Tile title={item.data.text}>
      <Container className="text-center">
        <img
          style={{ maxWidth: "100%" }}
          src={item.data.image}
          alt={item.data.text}
          height={300}
        />
        <Row>
          <a
            href={item.data.link}
            target="_blank"
            rel="noopener noreferrer">
            View
          </a>
        </Row>
      </Container>
    </Tile>
  );
};

export const Packing: React.FC = () => {
  const { logic } = useEloise();
  const {
    docs: items,
    loading,
    error,
  } = logic.fb.hooks.useCollection("packing", true);

  return (
    <Container fluid>
      <Row>
        <Heading
          size={4}
          style={{ textDecoration: "underline 1px solid" }}>
          Packing List
        </Heading>
      </Row>
      <Row>
        {items &&
          items.map((item: any, index: number) => (
            <Col
              key={index}
              lg={4}
              className="mx-auto">
              <Item item={item} />
            </Col>
          ))}{" "}
      </Row>
    </Container>
  );
};
