export function parseAirbnbHTML(htmlString: string) {
  // Create a new DOMParser instance
  const parser = new DOMParser();

  // Parse the HTML string
  const doc = parser.parseFromString(htmlString, "text/html");

  // Extract the anchor elements
  const anchors = doc.querySelectorAll(".airbnb-embed-frame a");
  let link1 = anchors[0] as HTMLLinkElement;

  // Extract the link and the text from the second anchor tag (as it contains the description)
  const link = link1 ? link1.href : "";
  const text = anchors[1] ? anchors[1].textContent : "";

  // Return the extracted information as an object
  return { link, text };
}
