import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Button, Heading, View, useEloise } from "typed-component-gallery";

export type airbnb = { text: string; link: string; price: number; id: string };
export const Airbnb: React.FC<airbnb> = ({ text, link, price, id: string }) => {
  function extractAirbnbId(link: string) {
    // Define the regular expression pattern to extract the number
    const pattern = /airbnb\.com\/rooms\/(\d+)/;
    const match = link.match(pattern);

    if (match && match[1]) {
      return match[1]; // Return the extracted number
    } else {
      return null; // Return null if no match is found
    }
  }
  useEffect(() => {
    // Function to load the Airbnb SDK script
    const loadAirbnbScript = () => {
      const script = document.createElement("script");
      script.src = "https://www.airbnb.com/embeddable/airbnb_jssdk";
      script.async = true;
      document.body.appendChild(script);
    };

    loadAirbnbScript();
  }, []);
  return (
    <div
      className="airbnb-embed-frame mx-auto "
      data-id={extractAirbnbId(link)}
      data-view="home"
      data-hide-price="true"
      style={{ width: "100%", margin: "auto" }}>
      <a href={link}>View On Airbnb</a>
      <a
        href={link}
        rel="nofollow">
        {text}
      </a>
    </div>
  );
};

export const AirbnbSelector: React.FC<{ ops: airbnb[]; id: string }> = ({
  ops,
  id,
}) => {
  const { logic, theme } = useEloise();

  const [expenses, setExpenses] = logic.fb.hooks.useThrottleBaseField<any>(
    `places/${id}`,
    "expenses",
    350
  );
  const [selectedId, setSelectedId] = useState<string | null>(null);

  useEffect(() => {
    if (expenses && expenses.length > 0) {
      let selected = expenses.filter((x: any) => x.title == "Housing");
      console.log(selected[0]);
      setSelectedId(selected[0].airbnb);
    }
  }, [expenses]);

  const onSelect = (selectedAirbnb: airbnb) => {
    // Update the selectedId state
    setSelectedId(selectedAirbnb.id);

    // Existing logic to update expenses
    let newExpenses = expenses.map((y: any) => {
      if (y.title === "Housing") {
        return {
          ...y,
          value: selectedAirbnb.price,
          airbnb: selectedAirbnb.id,
        };
      }
      return y;
    });
    setExpenses(newExpenses);
  };

  return (
    <Row className="airbnb-selector ">
      {ops.length > 0 &&
        ops.map((option) => (
          <Col
            lg={5}
            key={option.id}
            className="airbnb-option mx-auto"
            style={{
              margin: "10px",
              border: "1px solid " + theme.colors[1],
              padding: "10px",
              borderRadius: "5px",
            }}>
            <Airbnb {...option} />

            <Row>
              <Col
                lg={9}
                className="mx-auto">
                <Row>
                  <Col
                    lg={12}
                    className="mx-auto">
                    <View>
                      <Heading size={2}>{`Monthly Rate: ${option.price.toFixed(
                        2
                      )}`}</Heading>
                    </View>
                  </Col>
                  <Row>
                    <Col
                      lg={6}
                      className="mx-auto">
                      <Button
                        onClick={() => onSelect(option)}
                        style={{
                          backgroundColor:
                            selectedId === option.id
                              ? theme.colors[1]
                              : theme.colors[0],
                          color: "white",
                          border: "none",
                        }}>
                        {selectedId === option.id ? "Selected" : "Select"}
                      </Button>
                    </Col>
                  </Row>
                </Row>
              </Col>
            </Row>
          </Col>
        ))}
      {ops.length % 2 !== 0 && (
        <Col
          lg={5}
          className="mx-auto"></Col>
      )}
    </Row>
  );
};
