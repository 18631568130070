import React, { useEffect, useState } from "react";
import { ArcherContainer, ArcherElement } from "react-archer";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router";
import { Heading, useEloise } from "typed-component-gallery";
import "../assets/index.css";
import { defaultFont } from "../pages/dash";
export const spotifyFont =
  "CircularSpTitle,CircularSpTitle-Tall,CircularSp-Arab,CircularSp-Hebr,CircularSp-Cyrl,CircularSp-Grek,CircularSp-Deva,var(--fallback-fonts,sans-serif)";
export const PlanWidget: React.FC<{ plan: any; apple: boolean }> = ({
  plan,
  apple,
}) => {
  const { logic } = useEloise();
  const navigate = useNavigate();

  const [places, setPlaces] = useState<any>();

  useEffect(() => {
    const fetchPlanData = async () => {
      let placeData = await Promise.all(
        plan.data.places.map(async (planPlace: any) => {
          return await logic.fb.docs.getBaseDoc("places/" + planPlace.place);
        })
      );
      if (placeData) {
        setPlaces(placeData);
      }
    };
    if (plan) {
      fetchPlanData();
    }
  }, []);
  return (
    <ArcherContainer className="my-5">
      <Row onClick={() => navigate("/plans/" + plan.id)}>
        {places &&
          places.map((place: any, i: number) => (
            <ArcherElement
              key={place.id}
              id={"test" + i}
              relations={
                i < places.length - 1
                  ? [
                      {
                        targetId: "test" + (i + 1),
                        targetAnchor: "left",
                        sourceAnchor: "right",
                        style: {
                          strokeDasharray: "5,5",
                          strokeColor: apple ? "rgb(60 60 67 / 60%)" : "white",
                        },
                      },
                    ]
                  : []
              }>
              <Col
                lg={3}
                className="mx-auto">
                <Row>
                  <img
                    onClick={() => navigate("/places/" + place.id)}
                    src={place.data.shape}
                    height="100px"
                  />
                </Row>
                <Row>
                  <Heading
                    style={{
                      fontWeight: apple ? "normal" : "bold",

                      fontFamily: apple ? defaultFont : spotifyFont,
                      color: apple ? "rgb(60 60 67 / 60%)" : "white",
                    }}
                    size={2}>
                    {place.data.city}
                  </Heading>
                </Row>
              </Col>
            </ArcherElement>
          ))}
        {/* <ArcherContainer
          lineStyle="straight"
          strokeColor="red">
          <Row>
            <ArcherElement
              id="test1"
              relations={[
                {
                  targetId: "test2",
                  targetAnchor: "left",
                  sourceAnchor: "right",
                  style: { strokeDasharray: "5,5" },
                },
                {
                  targetId: "test3",
                  targetAnchor: "left",
                  sourceAnchor: "right",
                  style: { strokeDasharray: "5,5" },
                },
              ]}>
              <Col
                lg={4}
                className="mx-auto"
                style={{
                  height: "200px",
                  width: "200px",
                  border: "1px solid black",
                }}></Col>
            </ArcherElement>
            <Col lg={4}>
              <Row className="mb-5">
                <ArcherElement id="test2">
                  <Col
                    lg={4}
                    className="mx-auto"
                    style={{
                      height: "200px",
                      width: "200px",
                      border: "1px solid black",
                    }}></Col>
                </ArcherElement>
              </Row>
              <Row>
                <ArcherElement id="test3">
                  <Col
                    lg={4}
                    className="mx-auto"
                    style={{
                      height: "200px",
                      width: "200px",
                      border: "1px solid black",
                    }}></Col>
                </ArcherElement>
              </Row>
            </Col>
          </Row>
        </ArcherContainer> */}
      </Row>
    </ArcherContainer>
  );
};

export const PlanWidgetLarge: React.FC<{
  plan: any;
  apple: boolean;
  manualPlaces?: any[];
  full?: boolean;
}> = ({ plan, apple, manualPlaces, full }) => {
  const { logic } = useEloise();
  const navigate = useNavigate();
  const [places, setPlaces] = useState<any>();

  useEffect(() => {
    const fetchPlanData = async () => {
      console.log(plan);
      let placeData = await Promise.all(
        plan.data.places.map(async (planPlace: any) => {
          return await logic.fb.docs.getBaseDoc("places/" + planPlace.place);
        })
      );
      if (placeData) {
        console.log(placeData);
        setPlaces(placeData);
      }
    };
    if (plan) {
      if (manualPlaces) {
        setPlaces(manualPlaces);
      } else {
        fetchPlanData();
      }
    }
  }, []);

  useEffect(() => {
    console.log(places);
  }, [places]);
  return (
    <div
      className="my-3"
      onClick={() => navigate("/plans/" + plan.id)}
      style={{
        border: full ? "none" : "1px solid black",
        borderRadius: "10px",
      }}>
      <Row>
        <Heading size={4}>{plan.data.title}</Heading>
      </Row>
      <ArcherContainer className="my-5">
        <Row>
          {places &&
            places.map((place: any, i: number) => (
              <ArcherElement
                key={place.id}
                id={"test" + i}
                relations={
                  i < places.length - 1
                    ? [
                        {
                          targetId: "test" + (i + 1),
                          targetAnchor: "left",
                          sourceAnchor: "right",
                          style: {
                            strokeDasharray: "5,5",
                            strokeColor: apple
                              ? "rgb(60 60 67 / 60%)"
                              : "white",
                          },
                        },
                      ]
                    : []
                }>
                <Col
                  lg={3}
                  xs={3}
                  sm={3}
                  className="mx-auto no-mobile">
                  <Row className="text-center">
                    <img
                      src={place.data.shape}
                      style={{
                        height: "auto",
                        margin: "0 auto",
                        width: "200px",
                      }}
                    />
                  </Row>
                  <Row onClick={() => navigate("/places/" + place.id)}>
                    <Heading
                      style={{
                        fontWeight: apple ? "normal" : "bold",

                        fontFamily: apple ? defaultFont : spotifyFont,
                        color: apple ? "rgb(60 60 67 / 60%)" : "white",
                      }}
                      size={4}>
                      {place.data.city}
                    </Heading>
                  </Row>
                </Col>
              </ArcherElement>
            ))}
        </Row>
      </ArcherContainer>
    </div>
  );
};
