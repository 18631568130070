import { Button, Container, Form } from "react-bootstrap";

export const PlaceForm = () => {
  // Implement form submission logic
  const handleSubmit = (event: any) => {
    event.preventDefault();
    // Process form data
    console.log(event);
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        {/* City */}
        <Form.Group controlId="formCity">
          <Form.Label>City</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter city"
            required
          />
        </Form.Group>

        {/* Country */}
        <Form.Group controlId="formCountry">
          <Form.Label>Country</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter country"
            required
          />
        </Form.Group>

        {/* Airbnbs (Assuming you want to capture just one for simplicity) */}
        <Form.Group controlId="formAirbnbs">
          <Form.Label>Airbnb</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Airbnb info"
            required
          />
        </Form.Group>

        {/* Expenses (Assuming you want to capture just one for simplicity) */}
        <Form.Group controlId="formExpenses">
          <Form.Label>Expense</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter expense info"
            required
          />
        </Form.Group>

        {/* ID */}
        <Form.Group controlId="formId">
          <Form.Label>ID</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter ID"
            required
          />
        </Form.Group>

        {/* Shape */}
        <Form.Group controlId="formShape">
          <Form.Label>Shape</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter shape"
            required
          />
        </Form.Group>

        {/* Info - Language */}
        <Form.Group controlId="formInfoLanguage">
          <Form.Label>Language</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter primary language"
          />
        </Form.Group>

        {/* Info - English */}
        <Form.Group controlId="formInfoEnglish">
          <Form.Label>English</Form.Label>
          <Form.Check
            type="checkbox"
            label="English spoken"
          />
        </Form.Group>

        {/* Info - Currency */}
        <Form.Group controlId="formInfoCurrency">
          <Form.Label>Currency</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter currency used"
          />
        </Form.Group>

        {/* Info - Visa */}
        <Form.Group controlId="formInfoVisa">
          <Form.Label>Visa</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter visa info"
          />
        </Form.Group>

        {/* iCloud */}
        <Form.Group controlId="formIcloud">
          <Form.Label>iCloud</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter iCloud link"
          />
        </Form.Group>

        {/* Submit Button */}
        <Button
          variant="primary"
          type="submit">
          Submit
        </Button>
      </Form>
    </Container>
  );
};
