import { Table } from "react-bootstrap";
import { Tile } from "./placeWidgets";

const WeatherDisplay: React.FC<{ weatherData: any; lg?: number }> = ({
  weatherData,
  lg,
}) => {
  return (
    <Tile
      title="Weather"
      lg={lg ?? 9}>
      <Table
        striped
        bordered
        hover>
        <thead>
          <tr>
            <th>Month</th>
            <th>High (°F)</th>
            <th>Low (°F)</th>
            <th>Rainfall (in)</th>
            <th>Rain Days</th>
          </tr>
        </thead>
        <tbody>
          {weatherData.map((data: any, index: number) => (
            <tr key={index}>
              <td>{data.month}</td>
              <td>{data.high}</td>
              <td>{data.low}</td>
              <td>{data.rainfall}</td>
              <td>{data.rainDays}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Tile>
  );
};

export default WeatherDisplay;
