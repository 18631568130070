import { mdiAirplane } from "@mdi/js";
import { useEffect, useState } from "react";
import { Col, Container, ListGroup, Row } from "react-bootstrap";
import { useParams } from "react-router";
import { Heading, LargeTextInput, useEloise } from "typed-component-gallery";
import { Tile } from "../widgets/placeWidgets";
import { PlanWidgetLarge } from "../widgets/plan";
import { ProfileExpenses } from "../widgets/profileExpenses";

export const Plan = () => {
  const { id } = useParams();
  const { logic } = useEloise();

  const [places, setPlaces] = useState<any>();
  const [plan, setPlan] = useState<any>();

  const [notes, setNotes] = logic.fb.hooks.useThrottleBaseField(
    "plans/" + id,
    "notes"
  );

  useEffect(() => {
    const fetchPlan = async () => {
      let planData = await logic.fb.docs.getBaseDoc("plans/" + id);
      if (planData) setPlan(planData);
      fetchPlanData(planData);
    };
    fetchPlan();
  }, []);

  const fetchPlanData = async (x: any) => {
    let placeData = await Promise.all(
      x.data.places.map(async (planPlace: any) => {
        return await logic.fb.docs.getBaseDoc("places/" + planPlace.place);
      })
    );
    if (placeData) {
      setPlaces(placeData);
    }
  };
  const generateFlightName = (i: number) => {
    if (i == 0) {
      return `Bwi => ${places[i].data.city}, ${places[i].data.country} `;
    }

    return `${places[i - 1].data.city}, ${places[i - 1].data.country} =>  ${
      places[i].data.city
    }, ${places[i].data.country}`;
  };

  const totalExpenses = () => {
    return places.reduce((acc: number, item: any) => {
      let expenseSum = item.data.expenses.reduce((iAcc: number, item2: any) => {
        iAcc += item2.value;
        return iAcc;
      }, 0);
      acc += expenseSum;
      return acc;
    }, 0);
  };

  const totalAirfare = () => {
    return (
      plan.data.places.reduce((acc: number, item: any) => {
        acc += item.flight;
        return acc;
      }, 0) + plan.data.returnFlight
    );
  };

  useEffect(() => {
    console.log(plan);
  }, [plan]);
  return (
    <Container fluid>
      <Row>
        {plan && places && (
          <PlanWidgetLarge
            full
            manualPlaces={places}
            plan={plan}
            apple={true}
          />
        )}
      </Row>
      <Row>
        <Col
          lg={12}
          className="mx-auto">
          <Tile title="Weather">
            <Row>
              {plan &&
                places &&
                places.length > 0 &&
                plan.data.places.map((place: any, i: number) => {
                  let date = place.date.toDate();
                  let month = date.getMonth();
                  let highTemp = places[i].data.weather[month].high;
                  let lowTemp = places[i].data.weather[month].low;

                  return (
                    <Col lg={4}>
                      <Heading size={2}>{`${highTemp}/${lowTemp}F`}</Heading>
                    </Col>
                  );
                })}
            </Row>
          </Tile>
        </Col>
      </Row>
      <Row>
        {places && places.length > 0 && (
          <Col
            lg={12}
            className="mx-auto">
            <Tile
              title="Flights"
              icon={mdiAirplane}>
              <Row>
                {plan.data.places.map((place: any, i: number) => (
                  <Col lg={4}>
                    <Heading size={3}>{place.flight.toString() + "$"}</Heading>
                    <Heading size={2}>{generateFlightName(i)}</Heading>
                  </Col>
                ))}
              </Row>
              <Row className="mt-5">
                <Col lg={3}></Col>
                <Col
                  lg={3}
                  className="mx-auto">
                  <Heading size={3}>
                    {plan.data.returnFlight.toString() + "$"}
                  </Heading>
                  <Heading size={2}>{`${places[places.length - 1].data.city}, ${
                    places[places.length - 1].data.country
                  } => Bwi `}</Heading>
                </Col>
                <Col
                  lg={3}
                  className="mx-auto">
                  <Heading size={3}>{totalAirfare() + "$"}</Heading>
                  <Heading size={2}>Total Cost</Heading>
                </Col>
                <Col lg={3}></Col>
              </Row>
            </Tile>
          </Col>
        )}
      </Row>{" "}
      {places && (
        <Row>
          <Col
            lg={12}
            className="mx-auto">
            <Tile title="Monthly Expenses">
              <Row>
                {places.map((place: any, i: number) => (
                  <ProfileExpenses
                    type="expenses"
                    id={place.id}
                  />
                ))}
              </Row>
              <Row className="mt-5">
                <Col lg={3}></Col>
                <Col
                  lg={3}
                  className="mx-auto">
                  <Heading size={3}>{totalExpenses() + "$"}</Heading>

                  <Heading size={2}>{`Total Monthly Expenses`}</Heading>
                </Col>
                <Col
                  lg={3}
                  className="mx-auto">
                  <Heading size={3}>
                    {(totalExpenses() / 3).toFixed(2) + "$"}
                  </Heading>
                  <Heading size={2}>{`Average Monthly Expenses`}</Heading>
                </Col>{" "}
                <Col lg={3}></Col>
              </Row>
            </Tile>
          </Col>
        </Row>
      )}
      {places && (
        <Row>
          <Col
            lg={6}
            className="mx-auto">
            <Tile title="Total Cost">
              <Row>
                <Col
                  lg={3}
                  className="mx-auto">
                  <Heading size={3}>Sum</Heading>

                  <Heading size={2}>
                    {totalExpenses() + totalAirfare() * 2 + "$"}
                  </Heading>
                </Col>
                <Col
                  lg={3}
                  className="mx-auto">
                  <Heading size={3}>Split</Heading>

                  <Heading size={2}>
                    {(totalExpenses() + totalAirfare() * 2) / 2 + "$"}
                  </Heading>
                </Col>
                <Col
                  lg={3}
                  className="mx-auto">
                  <Heading size={3}>Monthly</Heading>
                  <Heading size={2}>
                    {((totalExpenses() + totalAirfare() * 2) / 3).toFixed(2) +
                      "$"}
                  </Heading>
                </Col>
                <Col
                  lg={3}
                  className="mx-auto">
                  <Heading size={3}>Split</Heading>

                  <Heading size={2}>
                    {((totalExpenses() + totalAirfare() * 2) / 3 / 2).toFixed(
                      2
                    ) + "$"}
                  </Heading>
                </Col>
              </Row>
            </Tile>
          </Col>
          <Col
            lg={6}
            className="mx-auto">
            <Tile title="Notes">
              <Row>
                <LargeTextInput
                  style={{ border: "none" }}
                  state={notes}
                  setState={setNotes}
                  placeholder=" "
                />
              </Row>
            </Tile>
          </Col>
        </Row>
      )}
      <Row>
        {plan && plan.data.shape && (
          <Col lg={6}>
            <Tile title="Route">
              <div className="text-center mx-auto">
                <img
                  src={plan.data.shape}
                  width={"90%"}
                />
              </div>
            </Tile>
          </Col>
        )}
        {plan && plan.data.info && (
          <Col lg={6}>
            <Tile title="Info">
              <ListGroup>
                <ListGroup.Item>
                  Duration: {plan.data.info.duration} days
                </ListGroup.Item>
                <ListGroup.Item>
                  Countries Visited: {plan.data.info.countries}
                </ListGroup.Item>
                <ListGroup.Item>
                  Cities Visited: {plan.data.info.cities}
                </ListGroup.Item>
                <ListGroup.Item>
                  Oceans: {plan.data.info.oceans.join(", ")}
                </ListGroup.Item>
                <ListGroup.Item>
                  Time on Coast: {plan.data.info.coast}%
                </ListGroup.Item>
                <ListGroup.Item>Miles: {plan.data.info.miles}%</ListGroup.Item>
              </ListGroup>
            </Tile>
          </Col>
        )}
      </Row>
    </Container>
  );
};
