import { Icon } from "@mdi/react";
import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { useEloise } from "typed-component-gallery";
interface CustomListItem {
  text: string;
  icon: string;
  ref?: React.RefObject<HTMLElement>;
  value?: string;
}

interface CustomListProps {
  items: CustomListItem[];
  selected?: string;
  setSelected?:
    | React.Dispatch<React.SetStateAction<string | undefined>>
    | React.Dispatch<React.SetStateAction<string>>;
  margin?: string;
}

const IconList: React.FC<CustomListProps> = ({
  items,
  selected,
  setSelected,
  margin,
}) => {
  const { theme } = useEloise();

  const handleClick = (item: any) => {
    if (setSelected) {
      setSelected(item.value ?? item.text);
    }
    if (item.ref && item.ref.current) {
      item.ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  useEffect(() => {
    console.log(selected);
  }, [selected]);

  return (
    <List className="mx-auto row">
      {items.map((item, index) => (
        <ListItem
          key={index}
          style={{
            margin,
            backgroundColor:
              (item.value ?? item.text) !== selected
                ? "white"
                : theme.colors[0],
          }}
          className={`col-lg-6 ${
            (item.value ?? item.text) === selected ? "selected" : ""
          }`}
          onClick={() => {
            handleClick(item);
          }}>
          <Row>
            <StyledIcon
              path={item.icon}
              color={
                (item.value ?? item.text) === selected
                  ? "white"
                  : theme.colors[0]
              }
              size={3}
            />
          </Row>
          <Row className="mx-auto">
            <Col
              className="mx-auto"
              style={{
                color:
                  (item.value ?? item.text) === selected ? "white" : "black",
              }}>
              {item.text}
            </Col>
          </Row>
        </ListItem>
      ))}
    </List>
  );
};

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const ListItem = styled.li`
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  text-align: center;
  background-color: white;
  border-radius: 4px;
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    transform: translateY(2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    background-color: #f2f2f2;
  }

  &.selected {
    transform: translateY(2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    background-color: #f2f2f2;
  }
`;

const StyledIcon = styled(Icon)`
  margin: 0 auto;
`;

export default IconList;
