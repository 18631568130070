import { SiteConfig } from "typed-component-gallery";
import logo from "../assets/whaleSeasonLogo.png";
import { Packing, Place, Places, Plans } from "../components/pages";
import Dash from "../components/pages/dash";
import { Plan } from "../components/pages/plan";

export const site: SiteConfig = {
  id: "whaleSeason",
  name: "Whale Season",
  logo: logo,
  inverseLogo: logo,
  api: "http://localhost:5001",
  defaultMode: "user",
  headerTrans: true,
  peopleConfig: [],
  sideWidget: [],
  pages: [
    {
      name: "/",
      component: <Dash />,
    },
    { name: "Places", component: <Places />, url: "places" },
    { name: "Place", hidden: true, component: <Place />, url: "place/:id" },
    {
      name: "Plans",
      component: <Plans />,
      url: "plans",
    },
    { name: "plan", hidden: true, url: "plans/:id", component: <Plan /> },
    { name: "Packing", component: <Packing /> },
  ],
  eloiseConfig: {
    endPoint: "http://127.0.0.1:5001/eloiselife-c5cf6/us-central1/musicGpt",
    chatLog: "vibez/chat",
    initMessage:
      "Hey there, Describe the music you are seeking and I will see what I can do. ",
  },
  hostingSite: "vibez",
};
