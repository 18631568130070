import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router";
import {
  Heading,
  UserDependentWidget,
  useEloise,
} from "typed-component-gallery";
import { Month } from "../../types/Month";
import { ProfileExpenses } from "../widgets/profileExpenses";
import { ProfileIncome } from "../widgets/profileIncome";

function capitalize(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const Profile = () => {
  const { name, type } = useParams();
  const { logic } = useEloise();
  const [data, setData] = useState<Month>();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<any>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const doc = await logic.fb.docs.getUserDoc(
          `profiles/types/${type}/${name}`
        );
        if (doc) {
          setData(doc);
        } else {
          // Handle the case when the document is not found
          console.error("Document not found");
        }
      } catch (err) {
        setError(err);
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };

    if (name) fetchData();
  }, [name, type]);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <UserDependentWidget>
      <Container fluid>
        <Row>
          <Heading size={3}>{`${capitalize(name ?? "Profile ")}`}</Heading>
        </Row>
        {data && name && (
          <Row>
            <Col
              lg={6}
              className="mx-auto">
              <ProfileExpenses
                id={name}
                type={type ?? "full"}
              />
            </Col>
            <Col
              lg={6}
              className="mx-auto">
              <ProfileIncome
                id={name}
                masterData={data}
                setMasterData={setData}
                type={type ?? "full"}
              />
            </Col>
          </Row>
        )}
      </Container>
    </UserDependentWidget>
  );
};
