import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router";
import {
  Button,
  ChildrenModal,
  Heading,
  Loading,
  View,
  useEloise,
} from "typed-component-gallery";
import { PlaceForm } from "../widgets/placeForm";
import { defaultFont } from "./dash";

export const Places: React.FC = () => {
  const { logic } = useEloise();
  const navigate = useNavigate();
  const {
    docs: places,
    loading,
    error,
  } = logic.fb.hooks.useCollection("places", true);
  const [newPlace, setNewPlace] = useState(false);

  if (loading) {
    return <Loading />;
  }

  return (
    <Container fluid>
      <ChildrenModal
        open={newPlace}
        onClose={() => setNewPlace(false)}>
        <PlaceForm />
      </ChildrenModal>
      <Row>
        {places

          .sort((a, b) => a.data.ns.localeCompare(b.data.ns))
          .sort((a, b) => a.data.lat - b.data.lat)
          .sort((a, b) => a.data.cont.localeCompare(b.data.cont))

          .map((place, i) => (
            <Col
              key={`place-${i}`}
              onClick={() => navigate("/place/" + place.id)}
              lg={4}
              className="text-center my-3">
              <img
                src={place.data.shape}
                height={300}
              />
              <Heading
                size={3}
                style={{
                  fontFamily: defaultFont,
                }}>{`${place.data.city}, ${place.data.country}`}</Heading>
            </Col>
          ))}
        <Col
          lg={4}
          className="text-center my-3">
          <View>
            <Button
              color={1}
              style={{ border: "none" }}
              onClick={() => setNewPlace(true)}>
              New Place
            </Button>
          </View>
        </Col>
      </Row>
    </Container>
  );
};
