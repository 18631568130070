/**
 * The TimeSpan in which something repeats
 */
type Period =
  | "Monthly"
  | "Daily"
  | "Quarterly"
  | "Yearly"
  | "Weekly"
  | "Custom";

export type CalculatedExpenseVar = { title: string; value: number; id: string };
/**
 * An Object that Represents a Calculated Expense
 * @property {string} title - string title that will show in UI
 * @property {Period} period - How often the expense is due
 * @property {number} count - How many of the unit
 * @property {string} unit - The unit of whatever is being calculated
 * @property {number} cost - how much one unit costs
 * @property {(count: number, cost: number) => number} [function] - Custom Function to Calculate Value takes count and cost
 */
export type CalculatedExpense = {
  title: string;
  period: Period;
  vars: Array<CalculatedExpenseVar>;
  unit: string;
  function?: () => number;
  type: "calculated";
  id: string;
};
/**
 * An Object that Represents a Flat Rate Expense
 * @property {string} title - string title that will show in UI
 * @property {Period} period - How often the expense is due
 * @property {number} value - How much in dollars
 */
export type FlatExpense = {
  title: string;
  period: Period;
  value: number;
  type: "fixed";
  id: string;
};
/**
 * An Combined Expense Type can be Either a Calulated or Flat Expense
 */
export type Expense = CalculatedExpense | FlatExpense;

export const splitExpenses = (
  exps: Array<Expense>
): [CalculatedExpense[], FlatExpense[]] => {
  const [calc, flat] = exps.reduce(
    ([calculated, flat]: any, expense: Expense) => {
      if (expense.type === "calculated") {
        return [[...calculated, expense], flat];
      } else {
        // Assuming the type can only be "calculated" or "fixed"
        return [calculated, [...flat, expense]];
      }
    },
    [[], []] // Initial values for calculated and flat arrays
  );
  return [calc, flat];
};
/**
 * An Income Object That Is Billed at a Flat Rate
 * @property {string} title - string title that will show in UI
 * @property {Period} period - How often the expense is due
 * @property {number} value - Total Income for Period
 */

export type FlatIncome = {
  title: string;
  period: Period;
  value: number;
  type: "fixed";
};

/**
 * An Income Object That Is Billed Per Hour
 * @property {string} title - string title that will show in UI
 * @property {Period} period - How often the expense is due
 * @property {number} rate - Hourly Rate
 * @property {number} hours - How many hours billed per period
 */
export type HourlyIncome = {
  title: string;
  period: Period;
  rate: number;
  hours: number;
  type: "calculated";
};

/**
 * Represents an income which can be either a flat income or an hourly income.
 */
export type Income = FlatIncome | HourlyIncome;

/**
 * Holds the Info Needed to Render the Airbnb Embed
 * @property {string} link - Link to Airbnb
 * @property {string} dataId - tells airbnb script which property obtain from airbnb
 */
export type Airbnb = {
  link: string;
  dataId: string;
};
/**
 * Describes the expenses and income for 1 month 
 * @property {Array<Expense>} expenses - An Array of all Monthly Expenses
 * @property {Array<Income>} income - An Array of all Monthly Income Streams

 */

export type MonthMoneyProfile = {
  expenses: Array<Expense>;
  income: Array<Income>;
};
/**
 * Describes Total Living Situation for Month
 * @property {string} id - uuid for document in firestore
 * @property {MonthMoneyProfile} money - Describes Expenses and Income
 * @property {string} state - the state you are living in
 * @property {string} city - the city you are living in
 * @property {string} country - the country you are living in
 * @property {Airbnb} [airbnb] - optional field to display airbnb embed
 * @property {period} [period] - optional field to save view mode
 *
 * @example
 * const Manglaralto:Month = {
 *
 * }
 *
 *
 */
export interface Month {
  id: string;
  expenses: Array<Expense>;
  income: Array<Income>;
  city: string;
  state: string;
  country: string;
  airbnb?: Airbnb;
  period?: Period;
}

export const MonthComponent: React.FC<{ id: string }> = ({ id }) => {
  return <></>;
};
